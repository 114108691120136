.react-tel-input .form-control {
    width: 100% !important;
    border: none !important;
    border-bottom: 1px solid #CACACA !important;
    padding: 8px 14px 8px 58px !important;
}

.react-tel-input .form-control:focus {
    border-color: inherit !important;
    box-shadow: none !important;
}

.react-tel-input .special-label {
    display: none !important;
}

.react-tel-input .selected-flag {
    padding: 0;
}
::-webkit-scrollbar {
    display: none;
}